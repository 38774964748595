import { environment } from '../../environments/environment'; //variable globales
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private userLoggedIn = new BehaviorSubject(false);

  getLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  getLoggedInValue(): boolean {
    return this.userLoggedIn.getValue();
  }

  setLoggedIn(val: boolean) {
    this.userLoggedIn.next(val);
  }

  constructor(private http: HttpClient, private router: Router, private authService: AuthService) {}

  PostLogin(log_user) {
    const credencialesDemo = {
      dni: '12345678Z',
      password: '123456789'
    };
    if (environment.demo) {
      if (JSON.stringify(log_user) != JSON.stringify(credencialesDemo)) {
        return throwError(
          new HttpErrorResponse({
            error: 'CredencialesKo',
            status: 401,
            statusText: 'Error'
          })
        );
      } else {
        return this.http.get('assets/misc/respuestas_API/login/res_login.json', { observe: 'response' });
      }
    } else {
      const httpOptions = {
          'Content-Type': 'application/x-www-form-urlencoded'
      };

      return this.http.post<any>(
        `/api/login`, `username=${log_user.dni}&password=${encodeURIComponent(log_user.password)}&grant_type=password`,
         { headers:httpOptions, observe: 'response'}
        )
    }
  }

  GetLogOut(datosCaducados: boolean) {
    this.authService.setUltimaAccion(null);
    this.setLoggedIn(false);
    localStorage.clear();
    this.router.navigateByUrl(`/login${datosCaducados ? '/r' : ''}`, { state: { sesionCaducada: datosCaducados } });
  }

  GetInfoUser() {
    if(environment.demo){
      return this.http.get(`assets/misc/respuestas_API/login/res_DatosPerfil.json`);
    } else{
      return this.http.get('/api/DatosPerfil');
    }
  }

  isUserAuthenticated() {
    if (!localStorage.getItem('user_token')) {
      return false;
    }
    return true;
  }

  public getRespuestaMensajesMantenimiento() {
    return this.http.get(`${environment.recursos}noticias/mantenimiento.json`).pipe(
      tap((respuesta) => {
        localStorage.setItem('mensajesMantenimiento', JSON.stringify(respuesta));
      })
    )
  }

  public getVersionApis() {
    return this.http.get('version/Select');
  }
}
