import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CanActivateGuard } from './shared/guards/can-activate.guard';
import { CanLoadAdministracionUsuariosGuard } from './shared/guards/can-load-administracion-usuarios.guard';
import { CanLoadAutoreservaGuard } from './shared/guards/can-load-autoreserva.guard';
import { CanLoadAutorizacionesGuard } from './shared/guards/can-load-autorizaciones.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./homepage/homepage.module').then((mod) => mod.HomepageModule),
    canLoad: [CanActivateGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./usuario/login/login.module').then((mod) => mod.LoginModule)
  },
  {
    path: 'password',
    loadChildren: () => import('./usuario/password/password.module').then((mod) => mod.PasswordModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then((mod) => mod.LandingModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./usuario/registro/registro.module').then((mod) => mod.RegistroModule)
  },
  {
    path: 'login/r',
    loadChildren: () => import('./usuario/login/login.module').then((mod) => mod.LoginModule)
  },
  {
    path: 'login/:dni/:password',
    loadChildren: () => import('./usuario/login/login.module').then((mod) => mod.LoginModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./usuario/perfil/perfil.module').then((mod) => mod.PerfilModule),
    canLoad: [CanActivateGuard]
  },

  {
    path: 'administracion',
    loadChildren: () => import('./administracion/administracion.module').then((mod) => mod.AdministracionModule),
    canLoad: [CanActivateGuard]
  },
  {
    path: 'autorreserva',
    loadChildren: () => import('./autorreserva/autorreserva.module').then((mod) => mod.AutorreservaModule),
    canLoad: [CanLoadAutoreservaGuard]
  },
  {
    path: 'gastos',
    loadChildren: () => import('./gastos-viajes/gastos-viajes.module').then((mod) => mod.GastosViajesModule),
    canLoad: [CanLoadAutoreservaGuard]
  },
  {
    path: 'travel-management',
    loadChildren: () => import('./presupuesto/presupuesto.module').then((mod) => mod.PresupuestoModule),
    canLoad: [CanActivateGuard]
  },
  {
    path: 'administracion-usuarios',
    loadChildren: () => import('./administracion-usuarios/administracion-usuarios.module').then((mod) => mod.AdministracionUsuariosModule),
    canLoad: [CanActivateGuard, CanLoadAdministracionUsuariosGuard]
  },
  {
    path: 'herramientas',
    loadChildren: () => import('./herramientas/herramientas.module').then((mod) => mod.HerramientasModule),
    canLoad: [CanActivateGuard]
  },
  {
    path: 'autorizaciones-viajes-enviadas', // como solicitante
    loadChildren: () => import('./autorizaciones-viajes/autorizaciones-viajes.module').then((mod) => mod.AutorizacionesViajesModule),
    canLoad: [CanActivateGuard]
  },
  {
    path: 'solicitud-autorizacion',
    loadChildren: () => import('./solicitud-autorizacion/solicitud-autorizacion.module').then((mod) => mod.SolicitudAutorizacionModule),
    canLoad: [CanActivateGuard]
  },
  {
    path: 'autorizaciones-viajes-recibidas', // como autorizador
    loadChildren: () => import('./autorizaciones-viajes/solicitudes-pendientes/solicitudes-pendientes.module').then((mod) => mod.SolicitudesPendientesModule),
    canLoad: [CanActivateGuard, CanLoadAutorizacionesGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
