import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorasMinutosPipe } from './HorasMinutosPipe';
import { SafePipe } from './SafePipe';
import { HighlightPipe } from './highlight.pipe';
import { DiasEntreFechasPipe } from './diasEntreFechas.pipe';

@NgModule({
  declarations: [HorasMinutosPipe, SafePipe, HighlightPipe, DiasEntreFechasPipe],
  imports: [
    CommonModule
  ],
  exports: [HorasMinutosPipe, SafePipe, HighlightPipe, DiasEntreFechasPipe]
})
export class PipesModule { }
