import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { AutoReservaService } from '../autorreserva.service';
import { InfoAutorizador } from 'src/app/autorreserva/autorreserva-compartido/modelos';
@Injectable({
  providedIn: 'root'
})
export class RentacarService extends AutoReservaService {
  infoUser$ = new BehaviorSubject<any>(null);
  ramasTraducidas$ = new BehaviorSubject<any>(null);

  private _proyectos = new BehaviorSubject<any>(null);
  proyectos$ = this._proyectos.asObservable();

  private _listaCochesRaw = new BehaviorSubject<any>([]);
  listaCochesRAW$ = this._listaCochesRaw.asObservable();

  private _cochesFiltrados = new BehaviorSubject<any>([]);
  cochesFiltrados$ = this._cochesFiltrados.asObservable();

  private _cochesFiltradosOriginal = new BehaviorSubject<any>([]);
  cochesFiltradosOriginal$ = this._cochesFiltradosOriginal.asObservable();

  private _coordenadas = new BehaviorSubject<{ latitud; longitud }>(null);
  coordenadas$ = this._coordenadas.asObservable();

  private _primerCocheNoCumple = new BehaviorSubject<any>(null);
  primerCocheNoCumple$ = this._primerCocheNoCumple.asObservable();

  private _peticion_rentacar = new BehaviorSubject<any>(null);
  _peticion_rentacar$ = this._peticion_rentacar.asObservable();

  private _info_user = new BehaviorSubject<any>(null);
  info_user$ = this._info_user.asObservable();

  private _autorizadoresViajes = new BehaviorSubject<InfoAutorizador[]>(null);
  autorizadoresViajes$ = this._autorizadoresViajes.asObservable();

  esAeropuerto: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  respuestaAeropuertoOrigen: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(protected http: HttpClient) {
    super(http);
  }

  GetDisponibilidad(peticion): Observable<any> {
    let tipo: number;
    peticion.Recogida === peticion.Devolucion ? (tipo = 0) : (tipo = 2);
    if (environment.pruebas || environment.demo) {
      return this.http.get(
        `assets/misc/respuestas_API/autoreserva/rentacar/busqueda_${this.getCadenaTipo(tipo)}_dev.json`
      );
    } else {
      var peticion_ = {
        FechaRecogida: peticion.FechaRecogida,
        FechaDevolucion: peticion.FechaDevolucion,
        Recogida: {
          Latitud: peticion.Recogida.Latitud,
          Longitud: peticion.Recogida.Longitud,
          Direccion: peticion.Recogida.Nombre
        },
        Devolucion: {
          Latitud: peticion.Devolucion.Latitud,
          Longitud: peticion.Devolucion.Longitud,
          Direccion: peticion.Devolucion.Nombre
        },
        CodigoCompany: peticion.CodigoCompany || null,
        NumeroVuelo: peticion.NumeroVuelo || null
      };
      return this.http.post('/api/DisponibilidadRentacar2', peticion_);
    }
  }

  GetMasInfoTarifa(id_coche, tipo = 0): Observable<any> {
    if (environment.pruebas || environment.demo) {
      return this.http.get(
        `assets/misc/respuestas_API/autoreserva/rentacar/prereserva_${this.getCadenaTipo(tipo)}_dev.json`
      );
    } else {
      return this.http.post('/api/MasInfoTarifa2', {
        id: id_coche
      });
    }
  }

  /*Modificado 24/04/18*/
  PostSeleccionarViajeros(peticion): Observable<any> {
    if (environment.demo) {
      return this.http.get('assets/misc/respuestas_API/autoreserva/seleccionar_viajeros.json');
    } else {
      return this.http.post('/api/SeleccionarViajeros', peticion);
    }
  }

  /**Modificado 16/05/18 */
  PostIniciarReserva(opciones_seleccionadas: any[]): Observable<any> {
    //Gestión de pasajeros
    // info_tarifa.pasajeros.forEach((p) => {
    //   var s = p.FechaNacimiento_.split('/');
    //   p.FechaNacimiento = new Date(s[2], parseInt(s[1]) - 1, s[0]);
    // });

    if (environment.demo) {
      return this.http.get('assets/misc/respuestas_API/autoreserva/rentacar/iniciar_reserva.json');
    }

    return this.http.post(
      '/api/IniciarReserva2',
      opciones_seleccionadas
      /*,
              ListaPasajeros : info_tarifa.pasajeros,
              EmailContacto : info_tarifa.pasajeros[0].Email*/
    );
  }

  getCadenaTipo(tipo) {
    let cadena;
    switch (tipo) {
      case 0:
        cadena = 'misma';
        break;

      case 2:
        cadena = 'dist';
        break;
    }
    return cadena;
  }

  deleteStateRentACar() {
    setTimeout(() => {
      this._peticion_rentacar.next(null);
      this._cochesFiltrados.next([]);
      this._listaCochesRaw.next([]);
      this._cochesFiltradosOriginal.next([]);
    }, 500);
  }

  guardarProyectos(proyectos) {
    this._proyectos.next(proyectos);
  }

  cambiarCochesRAW(coches) {
    this._listaCochesRaw.next(coches);
  }

  cambiarCochesFiltrados(coches) {
    this._cochesFiltrados.next(coches);
  }

  setCochesFiltradosOriginalPorHorario(coches) {
    this._cochesFiltradosOriginal.next(coches);
  }

  setCoordenadas(coordenadas: { latitud; longitud }) {
    this._coordenadas.next(coordenadas);
  }

  cambiarPrimerCocheNoCumple(primerCocheNoCumple) {
    this._primerCocheNoCumple.next(primerCocheNoCumple);
  }

  enviarPeticionRentaCar(peticionRentaCar) {
    this._peticion_rentacar.next(peticionRentaCar);
  }

  enviarInfoUser(infoUser) {
    this._info_user.next(infoUser);
  }

  unsubscribe() {
    this._peticion_rentacar.unsubscribe();
  }

  setAutorizadoresViajes(autorizadores: InfoAutorizador[]) {
    this._autorizadoresViajes.next(autorizadores);
  }

  getAutorizadoresViajes():InfoAutorizador[] {
    return this._autorizadoresViajes.getValue();
  }
}

export interface peticion {
  Recogida: {
    Latitud: string;
    Longitud: string;
  };
  Devolucion: {
    Latitud: string;
    Longitud: string;
  };
  FechaRecogida: string;
  FechaDevolucion: string;
}
