export const environment = {
  production: false,
  recursos: `https://login.consultiatravel.es/RECURSOS/octopus_Test/`,
  generalURL: 'https://apitest-destinux.consultiatravel.es/',
  // generalURL: 'https://login.consultiatravel.es/TEST/proxy/',
  test: true,
  pruebas: false,
  demo: false,
  show: false
};
