import { Component, OnDestroy, OnInit } from '@angular/core';
import * as env from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LoginService } from '../../servicios/login.service';
import { Usuario } from '../../modelos/usuario';
import { ServicioGeneralService } from 'src/app/servicios/servicio-general.service';
import { TranslateService } from '@ngx-translate/core';
import { Funciones } from 'src/assets/funcionesComunes';
import { IMantenimiento, IRespuestaMantenimiento } from 'src/app/modelos/mensajes/mantiemiento';
import * as dayjs from 'dayjs';
import { AutorizacionesViajesService } from 'src/app/servicios/autorizaciones-viajes.service';
import { SolicitudAutorizacionModel } from 'src/app/autorreserva/autorreserva-compartido/modelos';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  environment: any = env.environment;
  usuario: Usuario;
  isIE = false;
  respuestaMantenimiento: IRespuestaMantenimiento;
  alertas: Alerta[] = [];
  colorAlerta: string;
  subscripcionAutorizaciones: Subscription;
  autorizacionesViajesSolicitante: SolicitudAutorizacionModel[] = [];
  subscripcionSolicitudesPendientes: Subscription;
  autorizacionesViajesAutorizador: any[] = [];
  esAutorizador: boolean = false;


  constructor(
    private loginService: LoginService,
    private servicioGeneral: ServicioGeneralService,
    private translate: TranslateService,
    private _autorizacionesViajesService: AutorizacionesViajesService
  ) {
    this.loginService.getLoggedIn().subscribe((loggedIn) => {
      if(loggedIn){
        this.esAutorizador = this._autorizacionesViajesService.comprobarUsuarioEsAutorizador();
        if(this.esAutorizador || environment.demo) this._autorizacionesViajesService.getSolicitudesAutorizacionAutorizador();
      } else {          
        this.esAutorizador = false;
      }
    });
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some((h) => h.test(window.location.host));

  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem('info_user'));
    this.isIE = this.servicioGeneral.getNavegadorIE();
    this.respuestaMantenimiento = JSON.parse(localStorage.getItem('mensajesMantenimiento'));
    this.getMensajeMantenimiento();
    this.cargarAlertas();

    if (this.alertas.length > 0) {
      this.colorAlerta = this.alertas[0].color;
    }
    this.subscripcionAutorizaciones = this._autorizacionesViajesService.listadoSolicitudesAutorizacion$.subscribe((valor) => {
      if(valor && valor.length > 0) this.autorizacionesViajesSolicitante = valor;
    });
    this.subscripcionSolicitudesPendientes = this._autorizacionesViajesService.listadoSolicitudesAutorizacionAutorizador$.subscribe((valor) => {
      if(valor && valor.length > 0) this.autorizacionesViajesAutorizador = valor;
    });
  }

  ngOnDestroy(): void {
    this.subscripcionAutorizaciones.unsubscribe();
    this.subscripcionSolicitudesPendientes.unsubscribe();
  }

  cerrarSesion() {
    Swal.fire({
      title: this.translate.instant('navbar.cerrar-sesion'),
      text: this.translate.instant('navbar.cerrar-sesion-texto'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6a88b1',
      cancelButtonColor: '#7f8187',
      confirmButtonText: this.translate.instant('navbar.cerrar-sesion'),
      cancelButtonText: this.translate.instant('navbar.cancelar')
    }).then((result) => {
      if (result.value) {
        this.loginService.GetLogOut(false);
      }
    });
  }

  switchMenu() {
    this.servicioGeneral.switchMostrarSidebar();
  }

  getDia(fecha: Date | string): string {
    return Funciones.getDia(fecha, this.servicioGeneral.getLenguaje())
  }

  getMensajeMantenimiento() {
    const fechasMantenimiento = this.respuestaMantenimiento.mantenimiento;
    if (!fechasMantenimiento || !Object.keys(fechasMantenimiento).length) {
      return false;
    }
    const fechaActual: any = new Date();
    const fechaFin: any = new Date(fechasMantenimiento.fechafin);
    if ((fechaFin - fechaActual) >= 0) {
      let dia = dayjs(this.respuestaMantenimiento.mantenimiento?.fechainicio).format('DD/MM/YYYY');
      let horaini = dayjs(this.respuestaMantenimiento.mantenimiento?.fechainicio).format('HH:mm');
      let horafin = dayjs(this.respuestaMantenimiento.mantenimiento?.fechafin).format('HH:mm');

      let alertaMantenimiento = this.translate.instant('mantenimientos.mensaje-navbar',
        {
          dia: dia,
          horaini: horaini,
          horafin: horafin
        }
      );

      let alerta: Alerta = {
        texto: alertaMantenimiento,
        icono: "fa fa-wrench",
        color: "#b32230"
      }
      this.alertas.push(alerta)
    }
  }
  cargarAlertas() {
    const mensajes = this.respuestaMantenimiento.alertas;

    mensajes.forEach(element => {
      if (element[this.translate.currentLang] !== "") {

        let alerta: Alerta = {
          texto: element[this.translate.currentLang],
          icono: (element.icon == "") ? "fa fa-exclamation-triangle" : element.icon,
          color: (element.color == "") ? "#b32230" : element.color
        };

        this.alertas.push(alerta);
      }
    });
  }

  getAlertas() {
    return this.alertas.length;
  }

  abrirModalAlertas() {
    let textosAlerta = [];
    this.alertas.forEach(alerta => {
      let html = '<div class="text-justify lista-alertas-navbar" style="color: ' + alerta.color + '">';
      html += '<div><i class="lista-alertas-navbar__icono ' + alerta.icono + '"></i></div>';
      html += '<div>' + alerta.texto + '</div>';
      html += '</div>';
      textosAlerta.push(html);
    });

    Swal.fire({
      html: textosAlerta.join("<hr>"),
      width: "80%"
    });
  }
  actualizarColor(event) {
    this.colorAlerta = this.alertas[event.page].color;
  }

}

export interface Alerta {
  texto: string,
  icono: string,
  color: string
}
