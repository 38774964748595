import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import * as dayjs from 'dayjs';
import { slotAPI } from "./../../environments/VersionAPI";

var customParseFormat = require('dayjs/plugin/customParseFormat');
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private loginService: LoginService, private authService: AuthService) {
    dayjs.extend(customParseFormat);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Montar peticion general
    const montarPeticion = (version) => {
      let url = "";
      if (environment.generalURL.includes('localhost')) {
        url = environment.generalURL + req.urlWithParams;
      } else {
        url = environment.generalURL + this.checkApi(req) + this.checkEnvironment() + this.checkVersion(version) + req.urlWithParams;
      }
      const httpRequest = new HttpRequest(
        <any>req.method,
        url,
        req.body,
        { headers: req.headers, responseType: req.responseType }
      );
      req = Object.assign(req, httpRequest);
    }

    if (req.url.includes('api/login')) { // Si es login, set ultima accion y monta peticion con versiones pero sin headers
      this.authService.setUltimaAccion();
      montarPeticion(true);
    } else if(req.url.includes('version/Select') || (req.url.includes('api/Alta') && !req.url.includes('api/AltaTarjeta')  && !req.url.includes('api/AltaUser')) || (req.url.includes('api/ConfirmCambioPassword'))){
      montarPeticion(false);
    } else {
      const restricciones = [ // Restricciones que tiene que cumplir para llamar a la api
        localStorage.getItem('user_token'),
        localStorage.getItem('idsesion'),
        !req.url.includes('RECURSOS'),
        !req.url.includes('assets')
      ]
      if (restricciones.every(restriccion => restriccion)) { // Cualquier otra llamada, monta la peticion con la version y con los headers
        const user_token = 'Bearer ' + localStorage.getItem('user_token');
        const idsesion = localStorage.getItem('idsesion');
        const ultima_accion = localStorage.getItem('ActionTemp');
        if (ultima_accion) {
          this.authService.setTiempoSesion();
          this.authService.setUltimaAccion(dayjs(ultima_accion, 'DD-MM-YYYY HH:mm:ss'));
        }
        if (this.authService.SesionCaducada) {
          this.loginService.GetLogOut(true);
        } else {
          this.authService.setUltimaAccion();
          montarPeticion(true);
          req = req.clone({
            setHeaders: {
              authorization: user_token,
              IDSesion: idsesion
            }
          });
        }
      }
    }
    return next.handle(req);
  }

  checkEnvironment() {
    let entorno = '';
    for (let [key, val] of Object.entries(environment)) {
      if (val && typeof val === 'boolean') entorno = key;
    }
    if (entorno === 'production') {
      return '/';
    } else {
      return `.${entorno.toUpperCase()}/`;
    }
  }

  checkApi(req: HttpRequest<any>) {
    return req.headers.get('apiDestino') ? req.headers.get('apiDestino') : 'SB'
  }

  checkVersion(version){
    if(environment.test){
      return (version) ? `Test${slotAPI}` : '';
    } else{
      return (version) ? localStorage.getItem("versionAPIS") : '';
    }
  }
}
